import React from 'react'
import images from "../../../../assets/images";
import '../Apartment/apart.css';


const Common = () => {
  return (
    <div className='Apart-aminities'>
         <div className="aminities">
                                <img id="logoimg" src={images.A13}/>
                                <p className="data">Lawn Care & Snow <br/>Removal included</p>
                        </div>
                        <div className="aminities">
                                <img id="logoimg" src={images.A12}/>
                                <p className="data">High Speed Internet Access<br/> Available</p>
                        </div>
                        <div className="aminities">
                                <img  id="logoimg" src={images.A9}/>
                                <p className="data">LED Lighting</p>
                        </div>
                        <div className="aminities">
                                <img id="logoimg" src={images.A10}/>
                                <p className="data">24 hour<br/> emergency maintenance</p>
                        </div>
                        
                        <div className="aminities">
                                <img id="logoimg" src={images.A7}/>
                                <p className="data">Assigned Mailboxes</p>
                        </div>

    </div>
  )
}

export default Common