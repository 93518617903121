import React from 'react'
import images from "../../../assets/images";
import './Room.css';

const Studyroom = () => {
  return (
    <div className='room-images
    '>
                  <img src={images.g5}/>
                  <img src={images.g8}/>
                  <img src={images.g9}/>

    </div>
  )
}

export default Studyroom