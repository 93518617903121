import React from "react";
import "./header.css";
import logo from "../../assets/images/logo.png";
import Button from "../../components/button/button";
import '../../styles/global.css'
import {Link} from "react-router-dom";

function Header() {
  return (
    <>
      <div className="header">
        <a href="/">
        <img src={logo} alt="abc" className="logo"></img>
        </a>
        <input class="menu-btn" type="checkbox" id="menu-btn" />
        <label class="menu-icon" for="menu-btn">
          <span class="navicon"></span>
        </label>
        <ul class="menu ">
          <li> 
          <Link to="/"
          class="nav-link nav-link-ltr" >
              Home
          
            </Link>
          </li>
           <li>
            <Link to="/aminities"
            class="nav-link nav-link-ltr" >
              Amenities
            </Link>
          </li>
            <li>
            <Link to="/apartments"
            class="nav-link nav-link-ltr" >
              Apartments
            </Link>
          </li>
            <li>
            <Link to="/gallery"
            class="nav-link nav-link-ltr" >
              Gallery
            </Link>
          </li>
          <li>
          <Link to="/contactus"
            class="nav-link nav-link-ltr" >
              Contact us
            </Link>
          </li>
          <li>
          <Link to="https://keyrenter046.appfolio.com/listings/rental_applications/new?listable_uid=00b6e65f-2b24-4b41-b5b9-5c7cf766325e&source=TenantTurner">

            <button className="btn">
              <p>Apply Tenant</p>
            </button>
            </Link>

          </li>
        </ul>
      </div>
    </>
  );
}
export default Header;
