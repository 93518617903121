import React from "react";
import './contactus.css';
import images from "../../assets/images";
import { useState } from "react";

function Contactus() {

    const[firstName,setFirstName]=useState('');
    const[lastName,setLastName]=useState('');
    const[phoneNumber,setPhoneNumber]=useState('');
    const[email,setEmail]=useState('');
    const[error,setError]=useState(false);

    const handleSubmit=(e)=>{
        e.preventDefault();
        if(firstName.length==0||lastName.length==0||phoneNumber.length==0||email.length==0){
            setError(true)
        }
console.log(firstName,lastName)
    }

    const options = ['React', 'Node', 'Express', 'Mssql', 'Mongodb'];
    const onOptionChangeHandler = (event) => {
        console.log("User Selected Value - ", event.target.value)
    }
 

    return(
        <div>
            <section className="section-1">
                    <img src={images.Cmain}/>
            </section>

<div className="contact-sec-2">

    
    <div className="form">


        <section className="section-2">
                <h1 id="heading" >Contact us</h1>
        </section>
       
<div id="formdiv">
    <section className="formdiv-sec1 flex ">
        <section className="w-[42%]">
        <input type="text" className="forminput w-[50%]" onChange={e=>setFirstName(e.target.value) } placeholder="First Name" /><br/>
        {error&&firstName.length<=0?<label className="text-red-900 font-thin w-[152%]   error12">FirstName is required.</label>:""}</section>
        <section className="w-[47%] rightinput" ><input type="text" className="forminput" onChange={e=>setLastName(e.target.value) } placeholder="Last Name" /><br/>
        {error&&lastName.length<=0?<label className="text-red-900 font-thin  error2">LastName is required.</label>:""}</section>

    </section>
    <br/>
    <section className="formdiv-sec2 flex ">
        <section className="w-[42%]">
        <input className="forminput" type="number" onChange={e=>setPhoneNumber(e.target.value) } placeholder="Phone Number" id="hah" /><br/>
        {error&&phoneNumber.length<=0?<label className="text-red-900 font-thin  error1">Number is required.</label>:""}
        </section>
        <section className="w-[47%] rightinput " >

        <input className="forminput" type="email" onChange={e=>setEmail(e.target.value) } placeholder="Email" /><br/>
        {error&&email.length<=0?<label className="text-red-900 font-thin error2">Email is required.</label>:""}
        </section>
    </section>
    <br/>


    <section className="formdiv-sec3 flex">
           <select onChange={onOptionChangeHandler} id="options">
  
             <option>Interested In</option>
              {options.map((option, index) => {
              return <option key={index} >  
             {option}
             </option>
             })}

             
            </select>
          <input type="date" className="w-[30%] formdate" placeholder="Moving in Date" />
</section>
   
        <textarea placeholder="Comments" className="comment" ></textarea>  
        <button placeholder="submit" onClick={handleSubmit} type="submit"> Submit</button>
        </div>      
    </div>
            
<section className="git">
<section style={{marginTop:"15%"}}>
                <h1 id="heading2" >Get in touch</h1>
        </section>

      <div style={{display:"flex",marginTop:"5%"}}>  <img style={{height:"40px",width:"40px"}} src={images.C1} alt="jf" /><p style={{marginLeft:"5%",marginTop:"2%"}}> (505) 555-125  </p></div>
      <div style={{display:"flex",marginTop:"5%"}}>  <img style={{height:"40px",width:"40px"}} src={images.C2} alt="jf" /> <p style={{marginLeft:"5%"}}>  4517 Washington Ave. Manchester, Kentucky 39495 </p></div>
        
        <div style={{display:"flex",marginTop:"8%",}}> 
    <a href="https://www.facebook.com/">     <img className="cimg" src={images.C3} alt="jf" /> </a> &nbsp;  &nbsp;
    <a href="https://www.instagram.com/"> <img className="cimg" src={images.C4} alt="jf" />  </a>   &nbsp;  &nbsp;
    <a href="https://twitter.com/">    <img className="cimg" src={images.C5} alt="jf" />  </a>    &nbsp;   &nbsp;
        
     <a href="https://www.youtube.com/" >  <img className="cimg" src={images.C6} alt="jf" />  </a> 
         
         </div>
        
</section>

    </div>


    <div style={{paddingBottom:"20%"}}>
    <iframe className="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1589.9802394519645!2d-83.76318816153402!3d37.153639230810356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884352a00e70879f%3A0x1ad06ed33b7003c!2sIangar!5e0!3m2!1sen!2sus!4v1684846965200!5m2!1sen!2sus"></iframe>
</div>









        </div>
    )
}
export default Contactus;