import React from "react";
import Flogo from "../../assets/images/Flogo.png";
import contactusic1 from "../../assets/icons/phone.png";
import contactusic2 from "../../assets/icons/locate.png";
import contactusic3 from "../../assets/icons/facebook1.png";
import contactusic4 from "../../assets/icons/insta1.png";
import contactusic5 from "../../assets/icons/twiteer.png";
import contactusic6 from "../../assets/icons/youtub.png";
import './footer.css';

function Footer() {
  return (
    <>
      <div className=" relative mt-10 bottom-0 left-0 bg-[#4D4C4B]">
        <div className="px-4 pt-10 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full md:px-20 lg:px-8">
          <div className="grid row-gap-10 mb-8 lg:grid-cols-6">
            <div className="grid grid-cols-1 gap-5 row-gap-8 lg:col-span-4 md:grid-cols-3 lg:ml-4">
              <div>
                <img className="" src={Flogo}></img>   
                <ul className="mt-10 space-y-4">
                  <li className="flex items-center">
                    <img src={contactusic1} className="w-9 h-auto" />
                    <a
                      href="/"
                      className="ml-2 text-white font-reg text-lg transition-colors duration-300  hover:text-white "
                    >
                      (505) 555-0125
                    </a>
                  </li>
                  <li className="flex items-center">
                    <img src={contactusic2} className="w-9 h-auto" />
                    <a
                      href="/"
                      className="ml-2 text-white font-reg text-lg duration-300  hover:text-white "
                    >
                      4517 Washington Ave. Manchester, Kentucky 39495
                    </a>
                  </li>
                </ul>
              </div>

              {/*   -----------------------------------   */}
              <div class="relative md:mt-0 mt-10 lg:ml-4">
              
                <ul className="md:pl-10 pl-0 space-y-5 ">
                  <li>
                    <a
                      href="/"
                      className="text-white navlink text-base	font-reg transition-colors duration-300  hover:text-[white]
                      "
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="/aminities"
                      className="text-white navlink text-base	font-reg transition-colors duration-300 hover:text-[white] "
                    >
                      Amenities
                    </a>
                  </li>
                  <li>
                    <a
                      href="/apartments"
                      className="text-white navlink text-base	font-reg transition-colors duration-300 hover:text-[white]"
                    >
                      Apartments
                    </a>
                  </li>
                  <li>
                    <a
                      href="/gallery"
                      className="text-white navlink text-base	font-reg transition-colors duration-300 hover:text-[white]"
                    >
                      Gallery
                    </a>
                  </li>
                  <li>
                    <a
                      href="/contactus"
                      className="text-white navlink text-base	font-reg transition-colors duration-300 hover:text-[white]"
                    >
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>

              {/* -------------------------------------------- */}
              <div className="md:mt-0 mt-10 lg:ml-4">
                
                <ul className=" space-y-5">
                  <li>
                    <a
                      href="/"
                      className="text-white navlink text-base	font-reg transition-colors duration-300 hover:text-[white]"
                    >
                      Terms of use
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-white navlink text-base	font-reg transition-colors duration-300 hover:text-[white]"
                    >
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-white navlink text-base	font-reg transition-colors duration-300 hover:text-[white]"
                    >
                      Cookie policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="text-white navlink text-base	font-reg transition-colors duration-300 hover:text-[white]"
                    >
                      Help center
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="lg:mt-1 mt-10 md:max-w-md lg:col-span-2">
              <span className="text-white	font-reg text-2xl font-medium tracking-wide">
              Connect with us              </span>

              <ul className="mt-10 space-y-4">
                 

                  <li>
                    <div className="flex items-center mt-4 space-x-4 sm:mt-0">
                      <a
                        href="/"
                        className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
                      >
                        <img src={contactusic3} className="h-9" />
                      </a>
                      <a
                        href="/"
                        className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
                      >
                        <img src={contactusic4} className="h-9" />
                      </a>
                      <a
                        href="/"
                        className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
                      >
                        <img src={contactusic5} className="h-9" />
                      </a>
                      <a
                        href="/"
                        className="text-gray-500 transition-colors duration-300 hover:text-teal-accent-400"
                      >
                        <img src={contactusic6} className="h-9" />
                      </a>
                    </div>
                  </li>
                </ul>
              
             
            </div>
          </div>
          <div className="flex flex-col justify-center pt-5 pb-5 border-t border-[#C6C6C4] w-full sm:flex-row">
            <p className="text-white text-sm">
             Copyright  ©  2023 Valley college park apartment
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
