import React from "react";
import './apart.css';
import images from "../../../../assets/images";

function Apart() {
    return(
        <div className="Apart-aminities">
                        <div className="aminities">
                                <img id="logoimg" src={images.A1}/>
                                <p className="data">Refrigerator</p>
                        </div>

                        <div className="aminities">
                                <img id="logoimg" src={images.A2}/>
                                <p className="data">Electric Range</p>
                        </div>

                        <div className="aminities">
                                <img id="logoimg" src={images.A3}/>
                                <p className="data">In Sink Food Disposal</p>
                        </div>

                        <div className="aminities">
                                <img id="logoimg" src={images.A4}/>
                                <p className="data">Microwave</p>
                        </div>

                        <div className="aminities">
                                <img id="logoimg" src={images.A5}/>
                                <p className="data">Trash disposal</p>
                        </div>

                        <div className="aminities">
                                <img id="logoimg" src={images.A6}/>
                                <p className="data">Washer & Dryer</p>
                        </div>


                        <div className="aminities">
                                <img id="logoimg" src={images.A8}/>
                                <p className="data">Plenty of Closet space</p>
                        </div>

                       

                        

                        <div className="aminities">
                                <img id="logoimg" src={images.A11}/>
                                <p className="data">Wall to Wall Vinyl LVT<br/> flooring</p>
                        </div>

                      
                       


        </div>
    )
}
export default Apart;